@font-face {
    font-family: hind;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("https://fonts.gstatic.com/s/hind/v16/5aU19_a8oxmIfNJdERySjQ.woff2") format("woff2");
}

// body fonts
$sans-serif-regular: arial, sans-serif;
$sans-serif-bold: arial, sans-serif;

// heading fonts
$heading-font: hind, serif;

// for more site granularity
$hero-heading-font: $heading-font;
$h1-heading-font: $heading-font;
$h2-heading-font: $sans-serif-regular;
$h3-heading-font: $sans-serif-bold;
$h4-heading-font: $sans-serif-regular;
$h5-heading-font: $sans-serif-bold;
$h6-heading-font: $sans-serif-bold;
$font-breadcrumb-active: $sans-serif-bold;

// Font Style
$font-style-italic: italic;

// --------------------------------
//  COLORS
// --------------------------------
// names taken from style guide

$brand-primary: #194996;
$brand-secondary: #0071eb;
$black: #000;
$dark-gray: #3c3c3c;
$medium-gray: #6e6e6e;
$light-gray: #b8b8b8;
$lighter-gray: #f2f2f2;
$white: #fff;
$accent1: #0071eb; // used in account, badges
$accent2: #194996;
$accent3: #194996; // Add to Cart button
$accent4: #0071eb; // Add to Cart hover
$trans: transparent;
$active-panel-bg: #0071eb;
$panel-bg: #194996;
$active-account-nav: $dark-gray;

// consistent messaging colors
$error-color: #dc3545;
$warning-color: #ffc107; // used in account
$success-color: #218739; // used in account

$logo-width-small: 100px;
$logo-height-small: 40px;
$logo-width-tablet: 125px;
$logo-height-tablet: 50px;
$logo-width-desktop: 13.9%; // 200px in 1440
$logo-height-desktop: 72px;

// top banner
$promo-gradient-bg: $brand-primary;

// add to cart modal banner
$promo-gradient-bg-right: $brand-primary;

// Aliased variables used in global
$site-input-border-radius: 4px;
$site-btn-border-radius: 4px;

// Button Fonts
$button-font: $sans-serif-bold;

// offset for the header search per brand
$header-search-offset: -70px;

// Increase all font-size values for DrLeonards by 2px
$body-text-size: 16px;

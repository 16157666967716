// Slots and Content Assets: Site-Level Styles
// should contain only stylistic rules, i.e. values that will change
// from site to site, such as colors and typography.

.content-asset {
    .account-options {
        li {
            background-color: $box-drk-bg;

            &:hover {
                background-color: $accent2;
                border-color: $accent2;
            }
        }
    }
}

.folder-slot {
    background-color: $base-bg;

    h2 {
        color: $accent1;
    }
}

.footer-top-promo .primary-cta span {
    display: block;

    @include media-breakpoint-up(md) {
        display: inline;
    }

    &:first-of-type {
        @include font-bold;
    }
}

// sample implementation content
.header-top-promo {
    height: rem(24px);

    @include line-height(24px);

    margin: 0;
    background: $promo-gradient-bg;
    text-align: center;
    color: $white;

    @include media-breakpoint-up(md) {
        height: rem(30px);

        @include line-height(30px);
    }

    @include media-breakpoint-up(lg) {
        height: rem(50px);

        @include line-height(50px);
    }

    // shown in desktop only
    img {
        vertical-align: middle;
        margin-right: rem(20px);
        position: relative;
        top: -6px;
        width: 60px;
    }

    .primary-cta {
        padding-right: rem(20px);
        letter-spacing: 0.2px;

        @include media-breakpoint-up(lg) {
            @include font-size(28px);

            letter-spacing: 0.4px;
        }

        span:first-of-type {
            @include type-h3;

            @include media-breakpoint-up(lg) {
                padding-right: rem(10px);
            }
        }
    }

    a {
        text-decoration: underline;
        vertical-align: top;
    }

    .icon {
        fill: $white;
        position: relative;
        top: -2px;
    }
}

// Footer Assets
.footer-top-promo-container {
    @include gradient ($footer-top-bg-light, $footer-top-bg-dark, circle);
}

.footer-top-promo {
    height: rem(50px);
    box-sizing: border-box;
    padding: rem(6px) rem(20px);
    color: $white;
    background: url("../images/footer-top-banner-bg.png") no-repeat center;

    @include media-breakpoint-up(md) {
        height: rem(60px);

        @include line-height(60px);

        text-align: center;
        padding: 0;
    }

    @include media-breakpoint-up(lg) {
        height: rem(80px);

        @include line-height(80px);
    }

    .primary-cta {
        @include line-height(19px);

        letter-spacing: 0;
        display: block;
        float: left;

        @include media-breakpoint-up(md) {
            display: inline-block;
            float: none;
            margin-right: rem(40px);

            @include font-size(24px);

            letter-spacing: -0.1px;
            line-height: inherit;
        }

        @include media-breakpoint-up(lg) {
            @include font-size(30px);
        }
    }

    img {
        margin: rem(12px) rem(10px) 0;
        vertical-align: top;
        max-width: rem(60px);
        box-shadow: $footer-img-shadow;

        @include media-breakpoint-up(lg) {
            max-width: rem(90px);
            margin-top: rem(13px);
        }
    }

    a {
        text-decoration: underline;
        vertical-align: top;
        margin: rem(9px) 0 0;
        display: inline-block;
        float: right;
        letter-spacing: 0.2px;

        @include media-breakpoint-up(md) {
            float: none;
            margin: 0;
        }

        .icon {
            fill: $white;
            position: relative;
            top: -2px;
        }
    }
}

// Added to Cart Sample Aset
.add-to-cart-banner {
    height: rem(50px);
    margin: 0;
    background: $promo-gradient-bg-right;
    color: $white;

    a {
        display: inline-block;
        line-height: normal;
        width: 100%;

        @include media-breakpoint-up(md) {
            text-align: center;
        }
    }

    img {
        padding: rem(5px) rem(10px);
        width: rem(63px);
        vertical-align: middle;
    }

    .primary-cta {
        color: $white;

        @include media-breakpoint-down(md) {
            display: inline-block;
            width: calc(100% - 99px);
            padding-right: rem(10px);
            vertical-align: middle;
        }

        @include media-breakpoint-up(md) {
            display: inline;

            @include line-height(50px);
        }

        span:first-of-type {
            @include font-reg($body-text-size);

            @include media-breakpoint-up(md) {
                @include font-size(18px);
            }
        }

        span.bold {
            @include font-bold;

            @include media-breakpoint-up(md) {
                @include font-size(20px);
            }
        }
    }
}

.header-bottom-promo {
    height: rem(24px);

    @include line-height(24px);

    margin: 0 0 rem(4px);
    background: $lighter-gray;
    text-align: center;

    @include media-breakpoint-up(md) {
        height: rem(30px);

        @include line-height(30px);
    }

    @include media-breakpoint-up(lg) {
        height: rem(40px);

        @include line-height(40px);
    }

    a {
        text-decoration: underline;
        vertical-align: top;
    }

    // top asset styles - desktop only
    &.top-asset {
        .primary-cta {
            @include font-bold(18px);
        }
    }

    // bottom asset styles - all viewports
    &.bottom-asset {
        color: $accent1;

        .primary-cta {
            padding-right: rem(10px);
        }

        @include media-breakpoint-down(md) {
            .primary-cta .bold {
                @include font-bold;
            }
        }

        @include media-breakpoint-up(md) {
            .primary-cta {
                @include font-bold(18px);

                span:last-of-type:not(.bold) {
                    @include font-reg;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            .primary-cta {
                @include font-bold(22px);
            }
        }

        a {
            @include font-bold;
        }
    }
}

.cart-recommendations,
.category-slot,
.product-slot {
    h1 {
        @include font-reg(58px);

        color: $inverted-text-color;
    }

    h2 {
        @include font-reg(29px);
        @include font-italic;

        color: $accent1;
    }

    h3 {
        @include font-reg(32px);

        background-color: $base-bg;
        color: $body-text-color-black;
        text-transform: uppercase;
    }

    .search-result-items {
        .product-name {
            a {
                color: $accent1;
            }
        }
    }
}

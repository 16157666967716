.br-m { display: none; }
.br-l { display: none; }
.br-xl { display: none; }
.br-s { display: block; }

@media screen and (min-width: 480px) {
    .br-s { display: none; }
    .br-l { display: none; }
    .br-xl { display: none; }
    .br-m { display: block; }
}

@media screen and (min-width: 768px) {
    .br-s { display: none; }
    .br-m { display: none; }
    .br-xl { display: none; }
    .br-l { display: block; }
}

@media screen and (min-width: 1025px) {
    .br-s { display: none; }
    .br-m { display: none; }
    .br-l { display: none; }
    .br-xl { display: block; }
}

.svg-icons .icon.svg-store-card-dims {
    width: 300px;
    height: 200px;
}

.breadcrumb li:last-child {
    font-weight: 700;
}

.menu-category a,
.mobile-menu-utility-user a {
    font-family: arial, sans-serif !important;
}

button.button-fancy-large {
    background: #eb0000 !important;
    border-color: #eb0000 !important;
    color: $white !important;

    &:hover {
        background: #f90404 !important;
        border-color: #f90404 !important;
    }
}
